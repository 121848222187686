<template>
  <div class="pvp-join"></div>
</template>

<script>
import pvpEnterLobbyMixin from '@/mixin/pvpEnterLobby';

export default {
  mixins: [pvpEnterLobbyMixin],
  beforeCreate() {
    this.$store.commit('env/setIsLoading', true);
  },
  created() {
    const lobbyId = this.$route.params.id;
    this.enterLobby(lobbyId);
  },
};
</script>
